.label-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-right: 30px;
}
.label {
  display: flex;
  align-items: center;
  gap: 6px;
}
.label1 {
  width: 25px;
  background-color: #085293;
  height: 25px;
}
.label2 {
  width: 25px;
  background-color: #0d83c9;
  height: 25px;
}
.label3 {
  width: 25px;
  background-color: #11a3ec;
  height: 25px;
}
.label4 {
  width: 25px;
  background-color: #50bef1;
  height: 25px;
}
.label5 {
  width: 25px;
  background-color: #b2e3fa;
  height: 25px;
}
