.btn-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: absolute;
    left: 50%;

}

.btn-upload-container span {
    font-size: 15px;
    font-weight: 600;
}

.btn-upload {
    border: 1px solid #50BEF1;
    background: #50BEF1;
    border-radius: 20px;
    padding: 10px 50px;
    color: white;
    font-weight: 700;
    transition: all 0.15s ease-in-out;
    cursor: pointer;

}

.btn-upload:hover {
    background: white;
    color: #50BEF1;

}