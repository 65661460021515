.data-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
}

.data-item {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.data-item:hover {
  transform: translateY(-5px);
}

.data-item h4 {
  margin-bottom: 10px;
  color: #333;
  font-weight: 600;
}

.data-item p {
  margin: 5px 0;
  color: #666;
}
