.map {
  overflow: hidden;
  width: 100%;

  margin-top: 30px;
}

.first-map {
  display: flex;
  align-items: center;
  justify-content: center;
}

.second-map {
  display: flex;
  align-items: center;
}

path {
  cursor: pointer;
}

path:hover {
  fill: red !important;
}

.App {
  padding: 30px;
  display: flex;
  width: 100%;
}

.map1 {
  width: 100%;
}

.map2 {
  width: 100%;
}

.map--svg {
  width: 100%;
  max-width: 330px;
}

.map--svg path {
  fill: #a4ced2;
  stroke: #fff;
  stroke-width: 1px;
  transition: fill 0.3s;
}

.map--svg path:hover {
  fill: #3538d2;
}

.map--svg .is-active path {
  fill: #3538d2;
}

.map-title {
  margin-left: 400px;
}

.is-active {
  color: red;
  font-weight: bold;
}

ul {
  list-style-type: none;
}

a {
  color: #000;
  text-decoration: none;
}

h3 {
  text-align: center;
}


.input-uplaod {
  display: none;
}