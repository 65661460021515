.select-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
}

.division {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 50px;
}

.tunisia-mini-container {
  position: absolute;
  right: 50px;
  bottom: 40px;
}
.tunisia-mini {
  width: 70px;
  height: 90px;
}
.tunisia-mini:hover {
  fill: red;
}
.second-map {
  position: relative;
}
