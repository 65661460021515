path {
  cursor: pointer;
}
.image-container {
  display: flex;
  justify-content: center;
}
.image-container > svg {
  height: 420px;
}

path:hover {
  fill: red !important;
}

p {
  min-width: 350px;
}

.modal-container.active {
  opacity: 1;
  visibility: visible;
}
