.modal-container{
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.result-container {
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  max-width: 400px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
}
.legend-container {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.result-container span {
  display: block;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
}

.result-container span:last-child {
  margin-bottom: 0;
}
